import { Container } from "react-bootstrap";
import React from "react";

import "./Footer.css";

function Footer({ version }) {
  return (
    <Container>
      <footer className="App-footer">
        Rankin Brand &copy; {new Date().getFullYear()} - Version {version}
      </footer>
    </Container>
  );
}

export default Footer;
