// @ts-check
import React, { useState } from "react";
import packageJson from "../package.json";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Navigation from "./components/Navigation/Navigation";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

/**
 * App main entrypoint
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
  const [version] = useState(packageJson.version);

  return (
    <div className="App">
      <Header />
      <Navigation />
      <Footer version={version} />
    </div>
  );
}

export default App;
