import React from "react";
import { Container } from "react-bootstrap";
import logo from "../../logo.png";

import "./Header.css";

function Header() {
  return (
    <Container className="App-header">
      <header>
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </Container>
  );
}

export default Header;
