import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useRef } from "react";

import "./Navigation.css";

function Navigation() {
  const $root = useRef(null);

  function homeButtonClickHandler(ev) {
    ev.preventDefault();
    if ($root.current !== null) {
      $root.current.style.height = "66vh";
    }
  }

  function shopButtonClickHandler(ev) {
    ev.preventDefault();
    if ($root.current !== null) {
      $root.current.style.height = "13vh";
    }
  }

  return (
    <Container className="App-navigation" fluid ref={$root}>
      <Row>
        <Col sm={4}></Col>
        <Col sm={2}>
          <Button
            className="App-link"
            onClick={homeButtonClickHandler}
            rel="noopener noreferrer"
          >
            HOME
          </Button>
        </Col>
        <Col sm={2}>
          <Button
            className="App-link"
            onClick={shopButtonClickHandler}
            rel="noopener noreferrer"
          >
            SHOP
          </Button>
        </Col>
        <Col sm={4}></Col>
      </Row>
    </Container>
  );
}

export default Navigation;
